import { SxStyleProp } from 'theme-ui';
import { Theme } from '@themed';

export const styles = {
  init: {
    position: 'relative',
    '.wrapper': {
      maxWidth: ['maxRapidContentWidthMobile', 'maxRapidContentWidthMobile', 'maxRapidContentWidthDesktop'],
    },
    mark: {
      color: (theme: Theme) => `${theme.colors?.background} !important`,
      display: ['block', 'block', 'block', 'inline'],
    },
    br: {
      display: ['none !important', 'none !important', 'none !important', 'block !important'], // hide linebreaks where possible
    },
    h1: {
      fontSize: ['m', 'l', '2.626rem'],
    },
  } as SxStyleProp,

  background: {
    position: 'absolute',
    top: 0,
    left: '-10vw',
    bottom: 0,
    right: 0,
    width: '120vw',
    height: '100%',
    bg: 'primary',
    zIndex: -1,
  } as SxStyleProp,

  image: {
    mb: ['inherit', 7, 'inherit'],
    borderRadius: '50%',
    ml: [0, 0, 0, '10vh'],
    width: ['80vw', '67vh'],
    height: ['80vw', '67vh'],
    '.parallax-resize': {
      transform: ['scale(1.2) !important', 'scale(1.1) !important'],
    },
  },
};
