import { SxStyleProp } from 'theme-ui';

export const referenceStyles = {
  section: {
    variant: 'grids.CompactSectionContentRTL',
    flexDirection: ['column-reverse', 'column-reverse', 'row-reverse'],
    mt: 5,
    gap: [0, 0, 0, 0, 5],
  },
  content: {
    mb: 7,
    mt: 4,
  },
  paragraph: {
    variant: 'styles.p',
    fontSize: ['xs', 'xs', 'xxs', 'xs', 's'],
  },
  title: {
    fontSize: ['s', 's', 's', 'm', 'l'],
  },
  aside: {
    position: 'relative !important',
    display: 'grid !important',
    gridTemplateAreas: '"images"',
    transform: ['translateX(15%) scale(0.95)', 'translateX(10%)', 'translateX(-2%) scale(0.75)', 'translateX(10%)', 'none'],
  } as SxStyleProp,
};
