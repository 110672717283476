import React from 'react';
import { Box } from 'theme-ui';
import { Paragraph } from '@core';
import { useTranslate } from '@modules';
import { styles } from './Divider.styles';

function Divider() {
  const __ = useTranslate();

  return (
    <Box sx={styles.background}>
      <Box sx={styles.text}>
        <Paragraph sx={styles.paragraph}>
          {__('pages.casestudies.divider.paragraph')}
        </Paragraph>
      </Box>
    </Box>
  );
}

export default Divider;
