import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { SectionWithContent } from '@core';
import { CaseStudy } from '@types';
import { useLocale, useTranslate } from '@modules';
import { Button } from '@themed';
import { Reference } from './Reference';
import { SupportContent } from './SupportContent';

export const reference = {
  aside: {
    transform: 'none',
  },
};

interface ReferenceListProps {
  type: 'rapid' | 'finno';
  link?: boolean;
}

export const ReferenceList = ({ type, link }: ReferenceListProps) => {
  const locale = useLocale();
  const __ = useTranslate();

  const caseStudyNodes = useStaticQuery(graphql`
  query {
    allCaseStudy {
      edges {
        node {
          slug
          title
          site
          subtitle
          teaser
          supportScope
          publishDate
          thumbnails{
            url
            alternativeText
          }
          language
        }
      }
    }
  }
`);
  const allCaseStudies: CaseStudy[] = caseStudyNodes.allCaseStudy.edges.map((node: { node: CaseStudy }) => node.node);

  const list = allCaseStudies.filter(node => (node.language === locale && node.site === type) && node);
  [list[0], list[1]] = [list[1], list[0]];

  // TODO: formatImage

  return (
    <SectionWithContent data-cy="casestudies-referencelist">
      {list && list.map((node) => (
        <Reference key={node.slug} {...node} sx={reference}>
          <SupportContent scope={node.supportScope} />
          {link && (
            <Link to={`/${locale}/case-study/${node.slug}`}>
              <Button sx={{ variant: 'buttons.cta', mt: 5 }}>{__('pages.casestudies.cases.button')}</Button>
            </Link>
          )}
        </Reference>
      ))}
    </SectionWithContent>
  );
};
