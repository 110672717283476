export const contentStyles = {
  support: {
    ul: {
      variant: 'lists.tagListType',
    },
  },
  subtitle: {
    variant: 'styles.h3',
  },
};
