import { SxStyleProp } from 'theme-ui';

export const styles = {
  background: {
    width: '120vw',
    height: '100%',
    minHeight: '20vh',
    bg: 'primary',
    display: 'flex',
    alignItems: 'center',
    zIndex: -1,
  } as SxStyleProp,
  text: {
    height: 'inherit',
    width: 'inherit',
    variant: 'styles.wideLayoutMaxWidth',
  },
  paragraph: {
    color: 'background',
    fontSize: ['xxs', 'xs', 's'],
    ml: [0, 6],
    px: [5, 0],
    maxWidth: ['25em', '100%'],
  } as SxStyleProp,
};
